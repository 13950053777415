import permissions from './permissions.json';
import emailTemplate from './email-template-service-shared';
import { asArray } from '@cawita/core-front';
import travellersUtils from './travellers-utils';

export const AvailablePermissions = permissions;
export const EmailTemplateService = emailTemplate.config;

export const Permissions = permissions;
export const PermissionPerCategory: Record<string, typeof permissions> = _groupBy(permissions, p => p.category);
export const PermissionPerAccessType: Record<string, typeof permissions> = _groupBy(permissions, p => p.accessTypes);
export const PermissionPerAccessTypePerCategory: Record<string, Record<string, typeof permissions>> = Object.entries(PermissionPerAccessType).reduce((map, [accessType, perms]) => {
    map[accessType] = _groupBy(perms, p => p.category)
    return map;
}, {});

export const MergeTravellers = travellersUtils.mergeTravellers;

function _groupBy<T, V = T>(
    items: T[], 
    key: (i: T) => string | string[],
    mapItem?: (i: T) => V
): Record<string, V[]> {
    return items.reduce((map, item) => {
        const keys = asArray(key(item) ?? []);
        keys.forEach(k => {
            if (!map[k]) map[k] = [];
            map[k].push(mapItem ? mapItem(item) : item);
        });
        return map;
    }, {});
}