import { ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, isset } from '@cawita/core-front';
import { User } from '@shared/models';
import { Subscription } from 'rxjs';
import { UserStore } from './user.store';

export type LoggedUserContext = {
  $implicit: User;
}

@Directive({
  selector: '[cwtLoggedUser]'
})
export class LoggedUserDirective implements OnInit, OnDestroy {

  private _sub: Subscription;
  private _ctx: LoggedUserContext = { $implicit: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

  constructor(
    private cdRef: ChangeDetectorRef,
    private tRef: TemplateRef<LoggedUserContext>,
    private vcRef: ViewContainerRef,
    private userStore: UserStore
  ) { }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

  ngOnInit(): void {
    console.log(this.userStore);
    this._sub = this.userStore.stableState$.subscribe({
      next: (state) => {
        console.log(state);
        this._ctx.$implicit = state.user;
        this._view.ensureState(isset(state.user));
        this.cdRef.markForCheck();
      }
    });
  }

}
