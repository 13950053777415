'use strict';

const { isNumber, isString } = require('@hints/utils/types');
const { TemplateConfiguration } = require('./template-utils');
const currencyService = require('./formatting/currency-service');

const config = new TemplateConfiguration({
    forgottenPassword: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    newPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    askNewPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    invitationMail: {
        type: 'mail',
        for: 'system',
        variables: {
            userName: { type: 'string', label: 'template-variables.user-name' },
            companyName: { type: 'string', label: 'template-variables.company-name' },
            url: { type: 'url', label: 'template-variables.url', required: true },
        }
    },
    invitationRefused: {
        type: 'mail',
        for: 'system',
        variables: {
            userHostName: { type: 'string', label: 'template-variables.user-host-name' },
            userName: { type: 'string', label: 'template-variables.user-name' },
            companyName: { type: 'string', label: 'template-variables.customer-name' },
        }
    },
    invitationAccepted: {
        type: 'mail',
        for: 'system',
        variables: {
            userHostName: { type: 'string', label: 'template-variables.user-host-name' },
            userName: { type: 'string', label: 'template-variables.user-name' },
            companyName: { type: 'string', label: 'template-variables.customer-name' },
        }
    },
    confirmationRegisterMail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    bookingConfirmation: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
            bookingId: { type: 'string', label: 'template-variables.booking-id', required: true },
            booking: { type: 'string', label: 'template-variables.booking', required: true },
        },
    },
    newBooking: {
        type: 'mail',
        for: 'system',
        variables: {
            customerName: { type: 'string', label: 'template-variables.customer-name', required: true },
            bookingId: { type: 'string', label: 'template-variables.booking-id', required: true },
            booking: { type: 'string', label: 'template-variables.booking', required: true },
            bookingStatus: { type: 'string', label: 'template-variables.booking-status', required: true },
            customerEmail: { type: 'string', label: 'template-variables.customer-email', required: true },
            comment: { type: 'string', label: 'template-variables.comment', required: true },
        },
    },
    generic: {
        type: 'mail',
        for: 'admin',
        variables: {}
    }
}, {
    currency: { validate: isNumber, format: v => currencyService.format(v) },
    url: { validate: isString, format: v => `<a href="${v}">${v}</a>` }
});

module.exports = {
    config
};