import { Injectable } from '@angular/core';
import { CwtStore } from '@cawita/core-front/state';
import { User } from '@shared/models';

export type UserState = {
    user: User;

}

@Injectable({ providedIn: 'root' })
export class UserStore extends CwtStore<UserState> {
    constructor() {
        super({
            initialized: false,
            loading: false,
            user: null
        });
    }

    public init(user: User) {
        console.log(user);
        this.setState(c => ({
            ...c,
            initialized: true,
            loading: false,
            user: new User(user)
        }));
    }

    public update(userData: User | Partial<User>) {
        this.setState(c => ({
            ...c,
            user: new User({
                ...(c.user),
                ...(userData ?? {})
            })
        }))
    }

    public clear() {
        this.setState(c => ({
            ...c,
            initialized: false,
            loading: false,
            user: null
        }));
    }

}