const { isEmptyOrNotSet, asArray } = require('@hints/utils/data');

function mergeTravellers(maintravellers, travellers) {
    if (isEmptyOrNotSet(maintravellers)) return [];
    if (isEmptyOrNotSet(travellers)) travellers = [];
    maintravellers = asArray(maintravellers);
    travellers = asArray(travellers);
    return maintravellers.map(bTraveller => {
        const matchingInRequest = travellers.find(t => [bTraveller.id, `T${bTraveller.ref}`, `T${bTraveller.reference}`].includes(t.id));
        if (isEmptyOrNotSet(matchingInRequest)) return bTraveller;
        return { ...matchingInRequest, ...bTraveller };
    });
}

module.exports = { mergeTravellers };